<template>
  <div class="border">
    <div class="help-div">
      <h2 class="text-center">HELP</h2>
      <div class="shadow" v-for="(data,i) in faq" :key="i">
        <h4 @click="show(i)" class="cursor p-0" style="text-align: left;">
          {{data.q}}
          <span v-if="visible(i)">
            <i class="material-icons">arrow_drop_up</i>
          </span>
          <span v-if="!visible(i)">
            <i class="material-icons">arrow_drop_down</i>
          </span>
        </h4>
        <div v-if="visible(i)">
          <div v-for="(s,j) in data.src" :key="j">
            <img :src="s" :alt="data.q" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const faqUser = [
  {
    q: "Login",
    src: [require("@/assets/img/faq/Login/login.png")]
  },
  {
    q: "Dashboard",
    src: [require("@/assets/img/faq/Dashboard/dashboard.png")]
  },
  {
    q: "Create Booking",
    src: [
      require("@/assets/img/faq/CreateBooking/qb1.png"),
      require("@/assets/img/faq/CreateBooking/qb2.png"),
      require("@/assets/img/faq/CreateBooking/qb3.png"),
      require("@/assets/img/faq/CreateBooking/2.png"),
      require("@/assets/img/faq/CreateBooking/t1.png"),
      require("@/assets/img/faq/CreateBooking/t2.png"),
      require("@/assets/img/faq/CreateBooking/t3.png"),
      require("@/assets/img/faq/CreateBooking/3.png"),
      
      ]
  },  
  {
    q: "View Pending",
    src: [
    require("@/assets/img/faq/ViewPending/1.png"),
    require("@/assets/img/faq/ViewPending/2.png"),
    require("@/assets/img/faq/ViewPending/3.png"),
    ]
  },
   {
    q: "Postal Services",
    src: [
      require("@/assets/img/faq/PostalServices/1.png"),
      require("@/assets/img/faq/PostalServices/2.png"),
     
      ]
  }, 
   {
    q: "Dispatch courier",
    src: [
      require("@/assets/img/faq/DispatchCourier/1.png")
      ]
  },
   {
    q: "Manifest",
    src: [
      require("@/assets/img/faq/Manifest/1.png")
      ]
  },
  {
    q: "Dispatch Encode",
    src: [
      require("@/assets/img/faq/Dispatch/1.png"),
      require("@/assets/img/faq/Dispatch/2.png"),
      require("@/assets/img/faq/Dispatch/3.png"),
      
      ]
  }, 
  {
    q: "Track and Trace",
    src: [
      require("@/assets/img/faq/Track and Trace/tracktrace.png"),
      require("@/assets/img/faq/Track and Trace/tracktrace2.png")
    ]
  },
  {
    q: "View Receipt",
    src: [require("@/assets/img/faq/ViewReceipt/1.png")]
  },

  {
    q: "Report",
    src: [
    require("@/assets/img/faq/Report/1.png"),
    require("@/assets/img/faq/Report/2.png"),
    require("@/assets/img/faq/Report/3.png"),
    ]
  },
 
  {
    q: "Profile Settings",
    src: [
      require("@/assets/img/faq/Profile settings/profilesettings.png"),
      
    ]
  }, 
  {
    q: "User Service Budget",
    src: [
      require("@/assets/img/faq/UserServiceBudget/1.png"),
      
    ]
  }
];
const faqCSU = [
  {
    q: "Login",
    src: [require("@/assets/img/faq/cs/login/1.png")]
  },
  {
    q: "Dashboard",
    src: [require("@/assets/img/faq/cs/dashboard/1.png")]
  },
  {
    q: "Track and Trace",
    src: [require("@/assets/img/faq/cs/tracktrace/1.png")]
  },
  {
    q: "Profile and Settings",
    src: [require("@/assets/img/faq/cs/profilesettings/1.png")]
  }
];
const faqHubAdmin = [
  {
    q: "Login",
    src: [require("@/assets/img/faq/adminHub/login/1.png")]
  },
  {
    q: "Dashboard",
    src: [require("@/assets/img/faq/adminHub/dashboard/1.png")]
  },
  {
    q: "Create Booking",
    src: [
      require("@/assets/img/faq/adminHub/createbooking/qb1.png"),
      require("@/assets/img/faq/adminHub/createbooking/qb2.png"),
      require("@/assets/img/faq/adminHub/createbooking/qb3.png"),
      require("@/assets/img/faq/adminHub/createbooking/1.png"),
      require("@/assets/img/faq/adminHub/createbooking/2.png"),
      require("@/assets/img/faq/adminHub/createbooking/3.png"),
      require("@/assets/img/faq/adminHub/createbooking/4.png"),
      require("@/assets/img/faq/adminHub/createbooking/5.png"),
      require("@/assets/img/faq/adminHub/createbooking/6.png"),
      require("@/assets/img/faq/adminHub/createbooking/7.png"),
      require("@/assets/img/faq/adminHub/createbooking/8.png"),
      
      
    ]
  },
  {
    q: "Draft",
    src: [
      require("@/assets/img/faq/adminHub/Draft/1.png")
      
    ]
  },

  {
    q: "View Pending",
    src: [
      require("@/assets/img/faq/adminHub/viewpending/1.png"),
      require("@/assets/img/faq/adminHub/viewpending/2.png"),
      require("@/assets/img/faq/adminHub/viewpending/3.png"),
    ]
  },
  {
    q: "Postal Services",
    src: [
      require("@/assets/img/faq/adminHub/PostalServices/1.png"),
      require("@/assets/img/faq/adminHub/PostalServices/2.png"),
     
      ]
  },
    {
    q: "Dispatch Courier",
    src: [
      require("@/assets/img/faq/adminHub/DispatchCourier/1.png"),
      require("@/assets/img/faq/adminHub/PostalServices/2.png"),
     
      ]
  }, 
  {
    q: "Manifest",
    src: [
      require("@/assets/img/faq/adminHub/Manifest/1.png")
      ]
  }, 
  {
    q: "Dispatch Encode",
    src: [
      require("@/assets/img/faq/adminHub/dispatch/1.png"),
      require("@/assets/img/faq/adminHub/dispatch/2.png"),
      require("@/assets/img/faq/adminHub/dispatch/3.png")
    ]
  },
  {
    q: "Hub Dispatch",
    src: [
      require("@/assets/img/faq/adminHub/hubdispatch/1.png"),
      require("@/assets/img/faq/adminHub/hubdispatch/2.png"),
      require("@/assets/img/faq/adminHub/hubdispatch/3.png"),
      
    ]
  },
  {
    q: "Track & Trace",
    src: [
      require("@/assets/img/faq/adminHub/trackandtrace/1.png"),
      require("@/assets/img/faq/adminHub/trackandtrace/2.png"),
    ]
  },
  {
    q: "View Receipt",
    src: [
      require("@/assets/img/faq/adminHub/viewprocessed/1.png"),
    ]
  },
   {
    q: "Invoice Check",
    src: [
      require("@/assets/img/faq/adminHub/Invoicecheck/1.png"),
      require("@/assets/img/faq/adminHub/Invoicecheck/2.png"),

    ]
  },
  {
    q: "Reports",
    src: [
      require("@/assets/img/faq/adminHub/reports/1.png"),
      require("@/assets/img/faq/adminHub/reports/2.png"),
      require("@/assets/img/faq/adminHub/reports/3.png"),
      require("@/assets/img/faq/adminHub/reports/4.png"),
      require("@/assets/img/faq/adminHub/reports/5.png"),
      require("@/assets/img/faq/adminHub/reports/6.png"),
    ]
  },
  {
    q: "Profile & Settings",
    src: [
      require("@/assets/img/faq/adminHub/profilesettings/1.png")
    ]
  },
    {
    q: "User Service Budget",
    src: [
      require("@/assets/img/faq/adminHub/UserServiceBudget/1.png"),
      require("@/assets/img/faq/adminHub/UserServiceBudget/2.png"),

    ]
  },
];
const faqAdmin = [
  {
    q: "Login",
    src: [require("@/assets/img/faq/admin/Login/login-admin.png")]
  },
  {
    q: "Dashboard",
    src: [require("@/assets/img/faq/admin/Dashboard/dashboard-guide-admin.png")]
  },
    {
    q: "Create Booking",
    src: [
      require("@/assets/img/faq/admin/CreateBooking/qb1.png"),
      require("@/assets/img/faq/admin/CreateBooking/qb2.png"),
      require("@/assets/img/faq/admin/CreateBooking/qb3.png")
      
    ]
  },
  {
    q: "Draft",
    src: [
      require("@/assets/img/faq/admin/Draft/1.png")
      
    ]
  },
  {
    q: "View Pending",
    src: [
      require("@/assets/img/faq/admin/ViewPending/viewpending.png"),
      
    ]
  },
  {
    q: "Track and Trace",
    src: [
      require("@/assets/img/faq/admin/Track and Trace/trackandtrace.png"),
      require("@/assets/img/faq/admin/Track and Trace/track-and-trace-details.png")
    ]
  },
  {
    q: "View Receipt",
    src: [
      require("@/assets/img/faq/admin/Booking History/view-processed-guide-admin.png")
    ]
  },
  {
    q: "Address Book",
    src: [
      require("@/assets/img/faq/admin/Address Book/address-book-guide-admin.png"),
      
    ]
  },
  {
    q: "Invoice Check",
    src: [
      require("@/assets/img/faq/admin/Invoicecheck/1.png"),
      require("@/assets/img/faq/admin/Invoicecheck/2.png"),

    ]
  },

  {
    q: "Report",
    src: [
      require("@/assets/img/faq/admin/Report/reports-1.png"),
      require("@/assets/img/faq/admin/Report/reports-2.png"),
      require("@/assets/img/faq/admin/Report/reports-3.png"),
      require("@/assets/img/faq/admin/Report/reports-4.png"),
      require("@/assets/img/faq/admin/Report/reports-5.png"),
      require("@/assets/img/faq/admin/Report/reports-6.png"),
    ]
  },
  // {
  //   q: "Profile Settings",
  //   src: [
  //     require("@/assets/img/faq/admin/Profile settings/profile1.png"),
  //     require("@/assets/img/faq/admin/Profile settings/profile2.png")
  //   ]
  // },
  {
    q: "Password Management",
    src: [
      require("@/assets/img/faq/admin/PasswordManagement/passwordmanagement1.png"),
      require("@/assets/img/faq/admin/PasswordManagement/passwordmanagement2.png")
    ]
  },
  {
    q: "Master Data",
    src: [
      require("@/assets/img/faq/admin/MasterData/1.png"),
      require("@/assets/img/faq/admin/MasterData/2.png"),
      require("@/assets/img/faq/admin/MasterData/3.png"),
      require("@/assets/img/faq/admin/MasterData/4.png"),
      require("@/assets/img/faq/admin/MasterData/5.png"),
      require("@/assets/img/faq/admin/MasterData/6.png"),
      require("@/assets/img/faq/admin/MasterData/7.png"),
      require("@/assets/img/faq/admin/MasterData/8.png"),
      require("@/assets/img/faq/admin/MasterData/9.png"),
      require("@/assets/img/faq/admin/MasterData/10.png"),
      require("@/assets/img/faq/admin/MasterData/11.png"),
      require("@/assets/img/faq/admin/MasterData/12.png"),
      require("@/assets/img/faq/admin/MasterData/13.png"),
      require("@/assets/img/faq/admin/MasterData/14.png"),
      require("@/assets/img/faq/admin/MasterData/15.png"),
      require("@/assets/img/faq/admin/MasterData/16.png"),
      require("@/assets/img/faq/admin/MasterData/17.png"),
      require("@/assets/img/faq/admin/MasterData/18.png"),
      require("@/assets/img/faq/admin/MasterData/19.png"),
      require("@/assets/img/faq/admin/MasterData/20.png"),
      require("@/assets/img/faq/admin/MasterData/21.png")
    ]
  },
  {
    q: "User Management",
    src: [
      require("@/assets/img/faq/admin/UserManagement/usermanagement1.png"),
      require("@/assets/img/faq/admin/UserManagement/usermanagement2.png")
    ]
  },
   {
    q: "User Service Budget",
    src: [
      require("@/assets/img/faq/admin/UserServiceBudget/1.png"),
      

    ]
  },
];

export default {
  name: "Help",
  data() {
    return {
      visibleQuestion: [],
      pageDepth: ["Help"],
      user: {},
      faq: faqUser
    };
  },
  created() {
    this.$emit("breadCrumbData", ["Help"]);
    try {
      this.auth();
    } catch (e) {
      localStorage.clear();
      this.toast.error("You must log in first");
      this.$router.push("/");
    }
    this.checkUser();
  },
  methods: {
    checkUser() {
      this.user = this.auth();
      console.log(this.user);
      console.log(this.user.user_type);
      if (this.user.user_type == "super_admin") {
        this.faq = faqAdmin;
      } else if(this.user.user_type == "hub_admin" ){
        this.faq = faqHubAdmin;
      } else if(this.user.user_type == "customer_service_user"){
        this.faq = faqCSU;
      } else {
        this.faq = faqUser;
      }
    },
    visible(q) {
      return this.visibleQuestion.indexOf(q) >= 0;
    },
    show(q) {
      if (this.visibleQuestion.indexOf(q) >= 0) {
        this.visibleQuestion.splice(this.visibleQuestion.indexOf(q), 1);
      } else {
        this.visibleQuestion.push(q);
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$root.$emit("pageHierarchy", vm.pageDepth);
    });
  }
};
</script>
<style scoped>
.help-div {
  margin-top: 100px;
  padding: 1em;
}
.cursor {
  cursor: pointer;
}
.shadow {
  border: 1px solid #e0e0e0;
  padding: 10px;
  margin: 10px 0;
}
.p-0 {
  padding: 0;
  margin: 10px;
}
h2 {
  padding: 0 0 2em 0;
}
img {
  max-width: 90%;
  margin: 0;
}
</style>